import React, { useState } from 'react';

const FORM_ENDPOINT =
  'https://public.herotofu.com/v1/d4bcc040-2b4d-11ee-b71d-7b8e71114ecd';

const Feedback = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Form response was not ok');
        }

        setSubmitted(true);
      })
      .catch(err => {
        // Submit the form manually
        e.target.submit();
      });
  };

  if (submitted) {
    return (
      <>
        <div className="text-2xl mb-5 text-center">
          Thank you, we appreciate your feedback!
        </div>
      </>
    );
  }

  return (
    <div>
      <p>
        Your feedback is important to us, please let us know how we're doing.
      </p>
      <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST">
        <div className="mb-3 pt-0">
          <textarea
            placeholder="Your feedback"
            name="message"
            className="px-3 py-3 placeholder-slate-700 font-medium text-slate-950 relative bg-white bg-white rounded text-sm border-2 border-slate-500 shadow outline-none focus:outline-none focus:ring w-full"
            required
          />
        </div>
        <div
          style={{
            textIndent: '-99999px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            position: 'absolute'
          }}
          aria-hidden="true"
        >
          <input type="text" name="_gotcha" tabIndex="-1" autoComplete="off" />
        </div>
        <div className="mb-3 pt-0">
          <button
            className="bg-red-700 text-white active:bg-red-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Feedback;
