import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { HomeHero } from '../components/hero';
import Feedback from '../components/feedback';
import styled from 'styled-components';
import breakpoints from '../components/utils/breakpoints';

const FeedbackPage = () => (
  <Layout>
    <Seo
      title="Shield Systems Academy Contact Information - Martial Arts in Knoxville, Tennessee"
      description="Contact information for Shield Systems Academy. Email and directions for our adult and kids mixed martial arts, brazilian jiu jitsu and kickboxing school."
      headTagData={{
        robots: 'noindex,nofollow'
      }}
    />
    <HomeHero />
    <Wrapper>
      <Feedback />
    </Wrapper>
  </Layout>
);

const Wrapper = styled.div`
  margin: 2.38rem auto 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem;

  @media (min-width: ${breakpoints.md}) {
    margin: 5rem auto 5rem auto;
  }
`;

export default FeedbackPage;
